import React, {useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Response} from '../../types/api-types'
import {
  MediaImage,
  Paragraph,
  VisionItem,
  VisionParagraph,
} from '../../types/paragraphs'
import GenericLink from '../shared/Link'
import MediaItem from '../shared/MediaItem'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

type PersonData = {
  quote: string | undefined
  personId: string
  first_name: string
  last_name: string
  job_title: string
}

const Vision = ({paragraph, response}: Props) => {
  const visionParagraph: VisionParagraph = response?.included?.find(
    vision => vision.id === paragraph.id,
  )
  const visionItems: VisionItem[] | undefined =
    visionParagraph.relationships.field_vision_item.data.map(visionItem =>
      response?.included?.find(paragraph => paragraph.id === visionItem.id),
    )

  const [visionData, setVisionData] = useState<PersonData | null>({
    quote: visionItems[0].attributes.field_quote,
    personId: visionItems[0].relationships.field_media?.data?.id,
    first_name: visionItems[0].attributes.field_first_name,
    last_name: visionItems[0].attributes.field_last_name,
    job_title: visionItems[0].attributes.field_job_title,
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const personObject: VisionItem = visionData
    ? (response?.included?.find(
        person => person.id === visionData.personId,
      ) as VisionItem)
    : (response?.included?.find(
        person =>
          person.id === visionItems[0].relationships.field_media.data.id,
      ) as VisionItem)

  const visionImage: MediaImage | undefined = response?.included?.find(
    image => image?.id === personObject?.id,
  )

  const backgroundColorHandler = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-red'
      case 1:
        return 'bg-yellow'
      case 2:
        return 'bg-purple'
      case 3:
        return 'bg-orange'
      default:
        break
    }
  }
  return (
    <section className="vision-block overflow-hidden flex">
      <Container fluid>
        <Row className="">
          <Col
            md={5}
            className={`py-5 vision-block__person d-none d-md-block ${backgroundColorHandler(
              activeIndex,
            )}`}
          >
            {visionImage ? (
              <div className="">
                <MediaItem
                  item={visionImage}
                  response={response}
                  layout="fill"
                  objectFit="cover"
                  imageClass="vision-block__person__image"
                ></MediaItem>{' '}
              </div>
            ) : null}
            {visionData?.quote ? (
              <span className="position-absolute text-white vision-block__person__quote font-weight-bold">
                {`"${visionData?.quote}"`}
                <span className="d-block mt-3 toptitle font-weight-bold text-uppercase">
                  {visionData.first_name} {visionData.last_name}{' '}
                  {visionData.job_title ? (
                    <span>- {visionData.job_title}</span>
                  ) : null}
                </span>
              </span>
            ) : null}
          </Col>
          <Col md={7} className="pl-lg-5">
            <div className="vision-block__labels py-5 pl-lg-5 justify-content-center flex-column d-flex">
              {visionItems
                ? visionItems.map((visionItem, index) => (
                    <div
                      className="py-1 h5 font-weight-light text-left"
                      onMouseEnter={() => {
                        setActiveIndex(index)
                        setVisionData({
                          quote: visionItem.attributes.field_quote,
                          personId:
                            visionItem.relationships.field_media?.data?.id,
                          first_name: visionItem.attributes.field_first_name,
                          last_name: visionItem.attributes.field_last_name,
                          job_title: visionItem.attributes.field_job_title,
                        })
                      }}
                      key={visionItem.id}
                    >
                      <span>{visionItem.attributes.field_label}</span>
                    </div>
                  ))
                : null}
              <GenericLink
                link={visionParagraph.attributes.field_button}
                extraClass="text-grey"
              >
                <span className="vision-block__button mt-5 text-dark-grey font-weight-bold d-flex align-items-center">
                  {visionParagraph.attributes.field_button?.title}
                </span>
              </GenericLink>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Vision
